<template>
  <v-touch @pan="panHandler" @rotate="rotateHandler">
    <div
      id="full_div"
      style="width: 100%; z-index: 2000; background-color: #283a5e"
      fill-height
    >
      <div class="detaBarra row">
        <v-card
          class="col-4"
          style="height: 70px; background-color: #171c31"
        ></v-card>
        <v-card
          class="col-4"
          style="
            height: 70px;
            display: flex;
            justify-content: center;
            text-align: center;
            background-color: #171c31;
            padding-top: 10px;
          "
          ><v-btn to="/"
            ><svgicon
              icon="logoamura"
              width="200"
              height="200"
              color="#fff"
            ></svgicon></v-btn
        ></v-card>
        <v-card
          class="col-4 text-right"
          style="
            height: 70px;
            display: flex;
            justify-content: flex-end;
            background-color: #171c31;
            padding-top: 10px;
            padding-right: 35px;
          "
          ><v-btn v-if="visibleManeuvers" to="/maniobras"
            ><svgicon
              icon="maniobras"
              width="50"
              height="50"
              color="#343d66"
            ></svgicon
          ></v-btn>
          <v-btn v-if="visibleNotAuthorized" to="/noautorizados"
            ><svgicon
              icon="nopermitidos"
              width="50"
              height="50"
              color="#343d66"
            ></svgicon
          ></v-btn>
          <v-btn v-if="visibleMap" to="/mapa"
            ><svgicon
              icon="localizacion"
              width="50"
              height="50"
              color="#c0f4b3"
            ></svgicon
          ></v-btn>
          <v-btn @click="accident()"
            ><svgicon
              icon="incidencias"
              width="50"
              height="50"
              class="accident-icon"
              color="#fba83e"
            ></svgicon
          ></v-btn>

          <v-btn class="logout-button" @click="logout()"
            ><svgicon
              icon="apagado"
              width="50"
              height="50"
              color="#475988"
            ></svgicon
          ></v-btn>
        </v-card>
      </div>

      <l-map
        ref="myMap"
        :zoom="zoom"
        :center="center"
        :options="mapOptions"
        :zoomAnimation="true"
        :trackResize="true"
        :tap="true"
        :bounceAtZoomLimits="false"
        :viewreset="ViewRestart"
        style="
          height: calc(100vh - 64px);
          witdh: 100%;
          z-index: 1;
          cursor: pointer;
        "
        @update:center="centerUpdate"
        v-on:click="getcoords($event)"
        @dblclick="incidencia($event)"
        @ready="doSomethingOnReady()"
      >
        <l-control-layers
          :position="topright"
          :collapsed="false"
          :sort-layers="true"
          :measures="true"
        />

        <l-tile-layer
          v-for="tileProvider in tileProviders"
          :key="tileProvider.name"
          :name="tileProvider.name"
          :visible.sync="tileProvider.visible"
          :url="tileProvider.baseUrl"
          format="image/png"
          layer-type="base"
          transparent
          class="titlelayer"
        />

        <l-wms-tile-layer
          v-for="layer in layers"
          :key="layer.name"
          :visible="layer.visible"
          :name="layer.name"
          :version="layer.version"
          :base-url="layer.baseUrl"
          :layers="layer.layers"
          transparent
          format="image/png"
          layer-type="overlay"
        />
        <l-marker
          v-for="(itemI, index) in incidencias"
          v-bind:key="'i' + index"
          :lat-lng="itemI.position"
          :visible="itemI.visible"
          :icon="itemI.icon"
          @click="onMarkerClick(itemI.content)"
          @dblclick="deleteMarker(index, itemI.id)"
        >
          <l-tooltip :content="itemI.content"></l-tooltip>
        </l-marker>
        <l-marker
          v-for="(itemO, index) in oilSpills"
          v-bind:key="'i' + index"
          :lat-lng="itemO.position"
          :visible="itemO.visible"
          :icon="itemO.icon"
          @click="onMarkerClick(itemO.content)"
          @dblclick="deleteOilSpillMarker(itemO)"
        >
          <l-tooltip :content="itemO.content"></l-tooltip>
        </l-marker>
        <l-marker
          v-for="(itemO, index) in wastewaters"
          v-bind:key="'i' + index"
          :lat-lng="itemO.position"
          :visible="itemO.visible"
          :icon="itemO.icon"
          @click="onMarkerClick(itemO.content)"
          @dblclick="deleteWastewaterMarker(itemO)"
        >
          <l-tooltip :content="itemO.content"></l-tooltip>
        </l-marker>
      </l-map>
      <div class="arrow_box bocadillo" v-show="show" v-bind:style="styleObject">
        <div class="TituloShip">
          <h2>{{ vessellPopup.ship }}</h2>
          <p>{{ vessellPopup.typeship }}</p>
          <div class="botonClosePopup">
            <v-icon
              color="white"
              class="iconPopUPClose"
              @click="closeDialogBocadillo()"
              >far fa-times-circle</v-icon
            >
          </div>
        </div>
        <div class="fotoVessel" v-if="vessellPopup.picture !== null">
          <img
            v-bind:src="vessellPopup.picture"
            width="213"
            @error="imageUrlAlt"
          />
        </div>
        <div class="fotoVessel" v-if="vessellPopup.picture == null">
          <img src="../assets/no-pic.jpg" width="213" @error="imageUrlAlt" />
        </div>
        <div class="textoBocadillo">
          <p v-if="vessellPopup.imo !== null">
            <strong>IMO: </strong>{{ vessellPopup.imo }}
          </p>
          <p v-else><strong>MMSI: </strong>{{ vessellPopup.mmsi }}</p>
          <p><strong>Port: </strong>{{ vessellPopup.distancepobr }}</p>
          <p><strong>ETA: </strong>{{ vessellPopup.distanceport }}</p>
          <p>
            <strong>Size: </strong>{{ vessellPopup.length }} x
            {{ vessellPopup.sleeve }} m
          </p>
          <p><strong>Status: </strong>{{ vessellPopup.state }}</p>
          <p>
            <strong>SOG/HDG: </strong>{{ vessellPopup.sog }} Kn /
            {{ vessellPopup.hdg }}º
          </p>
          <p><strong>Dwt: </strong>{{ vessellPopup.dwt }}</p>
          <p><strong>GT: </strong>{{ vessellPopup.gt }}</p>
          <p><strong>Draught: </strong>{{ vessellPopup.draft }}</p>
          <br v-if="predictiveApiEnabled"/>
          <br v-if="predictiveApiEnabled"/>
          <p v-if="predictiveApiEnabled"><strong>Predictive models:</strong></p>
          <br v-if="predictiveApiEnabled"/>
          <br v-if="predictiveApiEnabled"/>
          <p v-if="vessellPopup.etd !== null && predictiveApiEnabled"><strong>ETD': </strong>{{ vessellPopup.etd }}</p>
          <p v-if="vessellPopup.etb !== null && predictiveApiEnabled"><strong>ETB': </strong>{{ vessellPopup.etb }}</p>
          <p v-if="vessellPopup.eta !== null && predictiveApiEnabled"><strong>ETA PBP': </strong>{{ vessellPopup.eta }}</p>
          <p v-if="vessellPopup.etc !== null && predictiveApiEnabled"><strong>ETC': </strong>{{ vessellPopup.etc }}</p>
        </div>
      </div>
      <div
        class="arrow_boxP bocadilloP"
        v-show="showP"
        v-bind:style="styleObject"
      >
        <div class="TituloShipP">
          <h2>{{ vessellPopup.ship }}</h2>
          <p>{{ vessellPopup.typeship }}</p>
          <div class="botonClosePopup">
            <v-icon
              color="dark"
              class="iconPopUPClose"
              @click="closeDialogBocadillo()"
              >far fa-times-circle</v-icon
            >
          </div>
        </div>
        <div class="fotoVessel" v-if="vessellPopup.picture !== null">
          <img
            v-bind:src="vessellPopup.picture"
            width="213"
            @error="imageUrlAlt"
          />
        </div>
        <div class="fotoVessel" v-if="vessellPopup.picture == null">
          <img src="../assets/no-pic.jpg" width="213" @error="imageUrlAlt" />
        </div>
        <div class="textoBocadillo">
          <p v-if="vessellPopup.imo !== null">
            <strong>IMO: </strong>{{ vessellPopup.imo }}
          </p>
          <p v-else><strong>MMSI: </strong>{{ vessellPopup.mmsi }}</p>
          <p><strong>Port: </strong>{{ vessellPopup.port }}</p>
          <p>
            <strong>Size: </strong>{{ vessellPopup.length }} x
            {{ vessellPopup.sleeve }} m
          </p>
          <p><strong>Status: </strong>{{ vessellPopup.state }}</p>
          <p>
            <strong>SOG/HDG: </strong>{{ vessellPopup.sog }} Kn /
            {{ vessellPopup.hdg }}º
          </p>
          <p><strong>Dwt: </strong>{{ vessellPopup.dwt }}</p>
          <p><strong>GT: </strong>{{ vessellPopup.gt }}</p>
          <p><strong>Draught: </strong>{{ vessellPopup.draft }}</p>
          <br v-if="predictiveApiEnabled"/>
          <br v-if="predictiveApiEnabled"/>
          <p v-if="predictiveApiEnabled"><strong>Predictive models:</strong></p>
          <br v-if="predictiveApiEnabled"/>
          <br v-if="predictiveApiEnabled"/>
          <p v-if="vessellPopup.etd !== null && predictiveApiEnabled"><strong>ETD': </strong>{{ vessellPopup.etd }}</p>
          <p v-if="vessellPopup.etb !== null && predictiveApiEnabled"><strong>ETB': </strong>{{ vessellPopup.etb }}</p>
          <p v-if="vessellPopup.eta !== null && predictiveApiEnabled"><strong>ETA PBP': </strong>{{ vessellPopup.eta }}</p>
          <p v-if="vessellPopup.etc !== null && predictiveApiEnabled"><strong>ETC': </strong>{{ vessellPopup.etc }}</p>
        </div>
      </div>
      <div
        class="arrow_boxPU bocadilloPU"
        v-show="showPU"
        v-bind:style="styleObject"
      >
        <div class="TituloShip">
          <h2>{{ vessellPopup.ship }}</h2>
          <p>{{ vessellPopup.typeship }}</p>
          <div class="botonClosePopup">
            <v-icon
              color="white"
              class="iconPopUPClose"
              @click="closeDialogBocadillo()"
              >far fa-times-circle</v-icon
            >
          </div>
        </div>
        <div class="fotoVessel" v-if="vessellPopup.picture !== null">
          <img
            v-bind:src="vessellPopup.picture"
            width="213"
            @error="imageUrlAlt"
          />
        </div>
        <div class="fotoVessel" v-if="vessellPopup.picture == null">
          <img src="../assets/no-pic.jpg" width="213" @error="imageUrlAlt" />
        </div>
        <div class="textoBocadillo">
          <p v-if="vessellPopup.imo !== null">
            <strong>IMO: </strong>{{ vessellPopup.imo }}
          </p>
          <p v-else><strong>MMSI: </strong>{{ vessellPopup.mmsi }}</p>
          <p><strong>ETA: </strong>{{ vessellPopup.distanceport }}</p>
          <p><strong>Port: </strong>{{ vessellPopup.distancepobr }}</p>
          <p>
            <strong>Size: </strong>{{ vessellPopup.length }} x
            {{ vessellPopup.sleeve }} m
          </p>
          <p><strong>Status: </strong>{{ vessellPopup.state }}</p>
          <p v-if="vessellPopup.hdg === 511">
            <strong>SOG/HDG: </strong>{{ vessellPopup.sog }} Kn / 0º
          </p>
          <p v-else><strong>SOG/HDG: </strong>{{ vessellPopup.sog }} Kn / 0º</p>
          <p><strong>Draught: </strong>{{ vessellPopup.draft }}</p>
          <p><strong>GT: </strong>{{ vessellPopup.gt }}</p>
          <p><strong>Dwt: </strong>{{ vessellPopup.dwt }}</p>
          <p>
            <strong>Noray's: </strong>{{ vessellPopup.noray1 }} -
            {{ vessellPopup.noray2 }}
          </p>
          <br v-if="predictiveApiEnabled"/>
          <br v-if="predictiveApiEnabled"/>
          <p v-if="predictiveApiEnabled"><strong>Predictive models:</strong></p>
          <br v-if="predictiveApiEnabled"/>
          <br v-if="predictiveApiEnabled"/>
          <p v-if="vessellPopup.etd !== null && predictiveApiEnabled"><strong>ETD': </strong>{{ vessellPopup.etd }}</p>
          <p v-if="vessellPopup.etb !== null && predictiveApiEnabled"><strong>ETB': </strong>{{ vessellPopup.etb }}</p>
          <p v-if="vessellPopup.eta !== null && predictiveApiEnabled"><strong>ETA PBP': </strong>{{ vessellPopup.eta }}</p>
          <p v-if="vessellPopup.etc !== null && predictiveApiEnabled"><strong>ETC': </strong>{{ vessellPopup.etc }}</p>

        </div>
      </div>
      <div
        class="arrow_boxPU bocadilloOilSpill"
        v-show="showOilSpillPopUp"
        v-bind:style="styleObject"
      >
        <div class="TituloShip">
          <h2>{{ oilSpillPopup.name }}</h2>
          <p></p>
          <div class="botonClosePopup">
            <v-icon
              color="white"
              class="iconPopUPClose"
              @click="closeDialogBocadillo()"
              >far fa-times-circle</v-icon
            >
          </div>
        </div>
        <div class="fotoVessel" v-if="oilSpillPopup.picture !== null">
          <img
            v-bind:src="oilSpillPopup.picture"
            width="273"
            @error="imageUrlAlt"
          />
        </div>
        <div class="fotoVessel" v-if="oilSpillPopup.picture == null">
          <img src="../assets/no-pic.jpg" width="273" @error="imageUrlAlt" />
        </div>
        <div class="textoBocadillo">
          <p class="description">
            <strong>Description: </strong>{{ oilSpillPopup.description }}
          </p>
          <p><strong>Sensor: </strong>{{ oilSpillPopup.sensor }}</p>
          <p>
            <strong>Acquisition date: </strong
            >{{ new Date(oilSpillPopup.acquisitionDate).toLocaleString("es-ES") }}
          </p>
          <p>
            <strong>Delivery date: </strong
            >{{ new Date(oilSpillPopup.deliveryDate).toLocaleString("es-ES") }}
          </p>
          <p v-if="oilSpillPopup.eventClass == 'W'">
            <strong>Category: </strong>W - Seawage
          </p>
          <p v-if="oilSpillPopup.eventClass == 'A'">
            <strong>Category: </strong>A - mineral oil (100% conclusiveness)
          </p>
          <p v-if="oilSpillPopup.eventClass == 'B'">
            <strong>Category: </strong>B - mineral oil (most likely)
          </p>
          <p v-if="oilSpillPopup.eventClass == 'C'">
            <strong>Category: </strong>C - mineral oil (less likely)
          </p>
          <p>
            <strong>Thin area: </strong>{{ oilSpillPopup.sheenArea }} km<sup
              >2</sup
            >
          </p>
          <p v-if="oilSpillPopup.name !== 'WASTEWATER'">
            <strong>Thick area: </strong>{{ oilSpillPopup.thickArea }} km<sup
              >2</sup
            >
          </p>
          <p v-if="oilSpillPopup.name !== 'WASTEWATER'">
            <strong>Very thick area: </strong>{{ oilSpillPopup.thickArea }} km<sup
              >2</sup
            >
          </p>
          <p>
            <strong>Min volume: </strong>{{ oilSpillPopup.minVolume }} m<sup
              >3</sup
            >
          </p>
          <p>
            <strong>Max volume: </strong>{{ oilSpillPopup.maxVolume }} m<sup
              >3</sup
            >
          </p>
          <p>
            <strong>Wind direction: </strong>{{ oilSpillPopup.windDirection
            }}<sup>º</sup>
          </p>
          <p>
            <strong>Wind speed: </strong>{{ oilSpillPopup.windSpeed }} km/h
          </p>
        </div>
      </div>
      <v-dialog
        v-model="dialog"
        persistent
        max-width="400"
        @click="closeDialog"
      >
        <v-card class="dialogBox">
          <div class="botonClose">
            <v-btn text @click="closeDialog()"
              ><v-icon color="white">far fa-times-circle</v-icon></v-btn
            >
          </div>

          <v-card-title class="headline textcolor txtCard"
            >Seleccione una Incidencia</v-card-title
          >

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="reportIncident('barco')"
              ><img src="@/assets/pin/barco.png" class="pin"
            /></v-btn>
            <v-btn @click="reportIncident('buzo')"
              ><img src="@/assets/pin/buzo.png" class="pin"
            /></v-btn>
            <v-btn @click="reportIncident('barrera')"
              ><img src="@/assets/pin/barrera.png" class="pin"
            /></v-btn>
            <v-btn @click="reportIncident('grua')"
              ><img src="@/assets/pin/grua.png" class="pin"
            /></v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-touch>
</template>
<script>
import axios from "axios";
import L from "leaflet";
import * as LWMS from "leaflet.wms";
import { CRS, latLng, icon, latLngBounds, map} from "leaflet";
import {
  LMap,
  LTileLayer,
  LMarker,
  LControlLayers,
  LPopup,
  LTooltip,
  LWMSTileLayer,
  LGeoJson,
  LControl,
} from "vue2-leaflet";
import "../compiled-icons/logoamura";
import WidgetsMenu from "../components/WidgtesMenu.vue";
import LogbookWidget from "./LogbookWidget.vue";
import authService from "@/services/authService";
import { amuraEvent } from "../models/amuraEvent";
import "leaflet-measure/dist/leaflet-measure.es.js";
import '../../node_modules/leaflet-measure/dist/leaflet-measure.css';
import '../customcss/leaflet-measure.css';
import keycloakService from '@/services/keycloakService';
import zidayService from '@/services/zidayService';
import predictionServices from '@/services/predictionServices';

export default {
  name: "Example3",

  components: {
    LMap,
    "l-wms-tile-layer": LWMSTileLayer,
    LControlLayers,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    LGeoJson,
    WidgetsMenu,
    LogbookWidget,
    LControl,
  },
  props: ["currentToken"],
  data() {
    let lat;
    let lon;
    let pointsX;
    let pointsY;
    let points;
    let info;
    let icono;
    let imgflag;
    let pic;
    let horasF;
    //let group1;
    let radar;
    let indice;

    let iconBarco = new L.icon({
      iconUrl: require("../assets/pin/pin-barco.png"),
      iconSize: [60, 60],
    });

    let iconGrua = new L.icon({
      iconUrl: require("../assets/pin/pin-grua.png"),
      iconSize: [60, 60],
    });
    let iconBarrera = new L.icon({
      iconUrl: require("../assets/pin/pin-barrera.png"),
      iconSize: [60, 60],
    });
    let iconBuzo = new L.icon({
      iconUrl: require("../assets/pin/pin-buzo.png"),
      iconSize: [60, 60],
    });
    let iconOilSpill = new L.icon({
      iconUrl: require("../assets/pin/pin-vertido.png"),
      iconSize: [60, 60],
    });
    let iconWastewater = new L.icon({
      iconUrl: require("../assets/pin/pin-aguasfecales.png"),
      iconSize: [60, 60],
    });

    let layersVar = [];

    if (process.env.VUE_APP_NAUTIC_CHART == "true") {
      layersVar.push({
        name: "Carta Náutica",
        visible: false,
        layers: "2,3,4,5,6",
        baseUrl: process.env.VUE_APP_NAUTIC_CHART_URL,
        version: "1.1.1",
        zIndex: 3,
      });
    }

    layersVar.push({
      name: "Radar",
      visible: true,
      layers: "radar",
      baseUrl: process.env.VUE_APP_GEOSERVER,
      version: "1.1.1",
      zIndex: 4,
    });

    return {
      widgets: true,
      loading: false,
      visible: false,
      geojson: null,
      windowSizeX: 0,
      windowSizeY: "1300px",
      zoom: parseInt(process.env.VUE_APP_ZOOM),
      center: latLng(
        parseFloat(process.env.VUE_APP_LAT),
        parseFloat(process.env.VUE_APP_LON)
      ),
      url: process.env.VUE_APP_URLMAPBOXFONDO,
      bounds: latLngBounds([
        [
          parseFloat(process.env.VUE_APP_BOUNDLATSUP),
          parseFloat(process.env.VUE_APP_BOUNDLONSUP),
        ],
        [
          parseFloat(process.env.VUE_APP_BOUNDLATINF),
          parseFloat(process.env.VUE_APP_BOUNDLONINF),
        ],
      ]),
      maxBounds: latLngBounds([
        [
          parseFloat(process.env.VUE_APP_BOUNDLATSUP),
          parseFloat(process.env.VUE_APP_BOUNDLONSUP),
        ],
        [
          parseFloat(process.env.VUE_APP_BOUNDLATINF),
          parseFloat(process.env.VUE_APP_BOUNDLONINF),
        ],
      ]),
      showNoray: false,
      iconBarco,
      iconBarrera,
      iconBuzo,
      iconGrua,
      iconOilSpill,
      iconWastewater,
      dialog: false,
      componentKey: 0,
      layers: layersVar,
      tileProviders: [
        {
          name: "Diurno",
          visible: true,
          attribution: process.env.VUE_APP_ATRIBUTTION,
          baseUrl: process.env.VUE_APP_URLMAPBOXDIURNO,
          zIndex: 1,
        },
        {
          name: "Nocturno",
          visible: false,
          baseUrl: process.env.VUE_APP_URLMAPBOXNOCTURNO,
          attribution: process.env.VUE_APP_ATRIBUTTION,
          zIndex: 1,
        },
      ],
      attribution: process.env.VUE_APP_ATRIBUTTION,
      currentZoom: parseInt(process.env.VUE_APP_ZOOM),
      currentCenter: latLng(
        parseFloat(process.env.VUE_APP_LAT),
        parseFloat(process.env.VUE_APP_LON)
      ),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 1,
        maxZoom: parseInt(process.env.VUE_APP_ZOOMMAX),
        minZoom: parseInt(process.env.VUE_APP_ZOOMMIN),
        draggable: false,
      },

      markers: [],
      vesselJson: [],
      stopMarkers: [],
      incidencias: [],
      oilSpills: [],
      wastewaters: [],
      adquisitions: [],
      currentEvents: [],
      points: [],
      info: [],
      geojson: null,
      markersNorays: [],
      flags: [],
      newNorays: [],
      capa: [],
      varBatimetria: false,
      incident: [],
      show: false,
      showE: false,
      showS: false,
      showP: false,
      showPU: false,
      showM: false,
      showN: false,
      showOilSpillPopUp: false,
      layerCarga: null,
      layerActual: null,
      layerCargaNombre: L.layerGroup(),
      layerActualNombre: L.layerGroup(),
      layerCargaStatic: null,
      layerActualStatic: null,
      layerNorays: null,
      layerFeatures: null,
      layerColorScale: null,
      layerActualNombreGruasStatic: null,
      layerSecurityAreasCargaStatic: null,
      layerSecurityAreasActualStatic: null,
      layerCargaDynamic: null,
      layerActualDynamic: null,
      layerActualCraneDynamic: null,
      layerOilSpills: null,
      layerWastewaters: null,
      layerContainer: null,
      redrawint: Math.floor(Math.random() * 200000) + 1,
      styleObject: {
        top: "364px",
        left: "1583px",
      },
      vessellPopup: [],
      predictiveApiEnabled: (/true/).test(process.env.VUE_APP_PREDICTION),
      oilSpillPopup: [],
      foto: "../assets/no-pic.jpg",
      group1: L.layerGroup(),
      visibleManeuvers: (/true/).test(process.env.VUE_APP_VISIBLE_MANEUVERS_BUTTON),
      visibleNotAuthorized: (/true/).test(process.env.VUE_APP_VISIBLE_NOTAUTHORIZED_BUTTON),
      visibleMap: (/true/).test(process.env.VUE_APP_VISIBLE_MAP_BUTTON)

    };
  },

  async created() {
    if (process.env.VUE_APP_GEOSERVER_ZIDAY !== "") {
      keycloakService.login();
    }
    this.estadoInicial();
    this.crearLocalStorage();
    await new Promise((resolve) => setTimeout(resolve, 500));
    this.loadOilSpillMarkers(0);
  },

  mounted() {
    let Pusher = document.createElement("script");
    Pusher.setAttribute("src", "//js.pusher.com/7.0/pusher.min.js");
    document.head.appendChild(Pusher);
    let ckeditor2 = document.createElement("script");
    ckeditor2.setAttribute("src", "//cdn.jsdelivr.net/npm/vue/dist/vue.min.js");
    document.head.appendChild(ckeditor2);
  },

  methods: {
    handleLayerChange(layer) {
      let styles = "";
      if (layer._url.includes(process.env.VUE_APP_IDMAPBOXDIURNO)) {
        styles = process.env.VUE_APP_LIGHT_STYLE_FEATURES;
      } else {
        styles = process.env.VUE_APP_NIGHT_STYLE_FEATURES;
      }
      this.map.removeLayer(this.layerFeatures);
      this.wmsfeaturesLayer(styles);
      this.map.addLayer(this.layerFeatures);
    },
    logout() {
      authService.logout();
      this.$router.replace("/login");
    },
    ViewRestart() {
      // console.log("redibujar" )
    },
    ocultar() {
      this.widgets = !this.widgets;
    },
    doSomethingOnReady() {
      this.map = this.$refs.myMap.mapObject;
      this.map.on("baselayerchange", (e) => {
        this.handleLayerChange(e.layer);
      });

      L.Control.Measure.include({
        // set icon on the capture marker
        _setCaptureMarkerIcon: function () {
          // disable autopan
          this._captureMarker.options.autoPanOnFocus = false;

          // default function
          this._captureMarker.setIcon(
            L.divIcon({
              iconSize: this._map.getSize().multiplyBy(2)
            })
          );
        },
      });

      let measureControl = new L.Control.Measure({
        position: "topleft",
        primaryLengthUnit: "meters",
        secondaryLengthUnit: "kilometers",
        primaryAreaUnit: "sqmeters",
        activeColor: "#293a5e",
        completedColor: "#2935a3",
        localization: 'es'
      });

      measureControl.addTo(this.map);

      if (process.env.VUE_APP_SECURITY_AREAS == "true") {
        this.wmsvessellSecurityAreasStatic();
        this.map.addLayer(this.layerSecurityAreasActualStatic);
      }
      if (process.env.VUE_APP_GEOSERVER_ZIDAY !== "") {
        this.wmsvessellOilSpills();
        this.map.addLayer(this.layerOilSpills);
        this.wmsvessellWastewaters();
        this.map.addLayer(this.layerWastewaters);
      }
      if (process.env.VUE_APP_GEOSERVER_CONTAINERS && process.env.VUE_APP_GEOSERVER_CONTAINERS !== "") {
        this.wmsContainers();
        this.map.addLayer(this.layerContainer);
      }
      this.wmsvessellNorays();
      this.map.addLayer(this.layerNorays);

      this.wmsfeaturesLayer(process.env.VUE_APP_LIGHT_STYLE_FEATURES);
      this.map.addLayer(this.layerFeatures);

      this.wmsvessellPoligon();

      this.map.addLayer(this.layerActualDynamic);
      this.wmscranePoligon();
      this.map.addLayer(this.layerActualCraneDynamic);

      this.layerActualDynamic.bringToBack();
      if (process.env.VUE_APP_SECURITY_AREAS == "true") {
        this.layerSecurityAreasActualStatic.bringToBack();
      }
      this.layerActualCraneDynamic.bringToFront();

      this.startInterval();
      this.startMessage();
    },
    ControlLayerAdd() {
      var layerControl = {
        berth: this.layers.name.Norays,
      };
      L.control.layers(layerControl).addTo(this.map);
    },
    imageUrlAlt(event) {
      event.target.src = "../img/no-pic.jpg";
    },
    getRedrawInteger() {
      return this.redrawint;
    },
    incrementRedrawInteger() {
      this.redrawint += 1;
    },
    crearLocalStorage() {
      let datosDB = null;
      axios
        .get(
          process.env.VUE_APP_API.trim() +
            "/api/incidents?port=" +
            process.env.VUE_APP_PUERTO,
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(authService.getUserLogged()).token,
            },
          }
        )
        .then((result) => {
          if (result.data) {
            result.data.data.forEach((value, key) => {
              this.addIncident(value);
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      if (datosDB === null) {
        this.incidencias = [];
      } else {
        this.incidencias = datosDB;
        this.incidencias.forEach(function (value) {
          value.icon = new L.icon(value.icon.options);
        });
      }
    },
    loadOilSpillMarkers(offset) {
      if (process.env.VUE_APP_GEOSERVER_ZIDAY !== "") {
        this.getOilSpills(0);
        this.getWastewaters(0);
      }
    },
    getOilSpills(offset) {
      zidayService.getAllOilSpills(offset)
      .then((result) => {
        if (result.data) {
          if ((parseInt(result.headers["x-total-count"]) - (offset + 10)) > 0) {
            this.getOilSpills(offset + 10);
          }
          result.data.forEach((value, key) => {
            if (!this.oilSpills.find((oilSpill) => oilSpill.id == value.id)) {
              this.addOilSpillMarker(value);
            }
          }, this);
        }
      })
      .catch(function (error) {
        if (error.response && error.response.status == 401) {
          keycloakService.login();
          zidayService.getAllOilSpills(offset)
          .then((result) => {
            if (result.data) {
              if ((parseInt(result.headers["x-total-count"]) - (offset + 10)) > 0) {
                this.getOilSpills(offset + 10);
              }
              result.data.forEach((value, key) => {
                if (!this.oilSpills.find((oilSpill) => oilSpill.id == value.id)) {
                  this.addOilSpillMarker(value);
                }
              }, this);
            }
          })
        }
      });
      
    },
    getWastewaters(offset) {
      zidayService.getAllWastewaters(offset)
      .then((result) => {
        if (result.data) {
          if ((parseInt(result.headers["x-total-count"]) - (offset + 10)) > 0) {
            this.getWastewaters(offset + 10);
          }
          result.data.forEach((value, key) => {
            if (!this.wastewaters.find((wastewater) => wastewater.id == value.id)) {
              this.addWastewaterMarker(value);
            }
          }, this);
        }
      })
      .catch(function (error) {
        if (error.response && error.response.status == 401) {
          keycloakService.login();
          zidayService.getAllWastewaters(offset)
          .then((result) => {
            if (result.data) {
              if ((parseInt(result.headers["x-total-count"]) - (offset + 10)) > 0) {
                this.getWastewaters(offset + 10);
              }
              result.data.forEach((value, key) => {
                if (!this.wastewaters.find((wastewater) => wastewater.id == value.id)) {
                  this.addWastewaterMarker(value);
                }
              }, this);
            }
          })
        }
      });
    },
    wmsvessellSecurityAreasStatic() {
      let randint = this.getRedrawInteger();
      this.layerSecurityAreasActualStatic = LWMS.overlay(
        process.env.VUE_APP_GEOSERVER + randint,
        {
          REQUEST: "GetMap",
          transparent: true,
          layers: process.env.VUE_APP_SECURITYAREAS_STATIC,
          format: "image/png",
          version: "1.1.1",
          visible: true,
        }
      );
    },
    wmsvessellNorays() {
      let randint = this.getRedrawInteger();
      this.layerNorays = LWMS.overlay(process.env.VUE_APP_GEOSERVER + randint, {
        REQUEST: "GetMap",
        transparent: true,
        layers: process.env.VUE_APP_NORAYS,
        format: "image/png",
        version: "1.1.1",
        visible: false,
        minZoom: 17,
      });
    },
    wmsfeaturesLayer(styles) {
      let randint = this.getRedrawInteger();
      this.layerFeatures = LWMS.overlay(
        process.env.VUE_APP_GEOSERVER + randint,
        {
          REQUEST: "GetMap",
          transparent: true,
          layers: process.env.VUE_APP_FEATURES,
          format: "image/png",
          version: "1.1.1",
          visible: true,
          styles: styles,
        }
      );
    },
    wmsvessellOilSpills() {
      let randint = this.getRedrawInteger();

      this.layerOilSpills = LWMS.overlay(
        process.env.VUE_APP_GEOSERVER_ZIDAY + randint,
        {
          REQUEST: "GetMap",
          transparent: true,
          layers: "ziday:oil_spills",
          format: "image/png",
          version: "1.1.0",
          visible: true,
        }
      );
    },
    wmsContainers() {
      let randint = this.getRedrawInteger();

      this.layerContainer = LWMS.overlay(
        process.env.VUE_APP_GEOSERVER_CONTAINERS + randint,
        {
          REQUEST: "GetMap",
          transparent: true,
          layers: "container:current_container",
          format: "image/png",
          version: "1.1.0",
          visible: true,
        }
      );
    },
    wmsvessellWastewaters() {
      let randint = this.getRedrawInteger();

      this.layerWastewaters = LWMS.overlay(
        process.env.VUE_APP_GEOSERVER_ZIDAY + randint,
        {
          REQUEST: "GetMap",
          transparent: true,
          layers: "wastewaterspills_actives",
          format: "image/png",
          version: "1.1.0",
          visible: true,
        }
      );
    },
    wmsvessellPoligon() {
      let randint = this.getRedrawInteger();
      this.layerActualDynamic = LWMS.overlay(
        process.env.VUE_APP_GEOSERVER + randint,
        {
          REQUEST: "GetMap",
          transparent: true,
          layers: process.env.VUE_APP_VESSELS,
          format: "image/png",
          version: "1.1.1",
          visible: true,
        }
      );
    },
    wmscranePoligon() {
      let randint = this.getRedrawInteger();
      this.layerActualCraneDynamic = LWMS.overlay(
        process.env.VUE_APP_GEOSERVER + randint,
        {
          REQUEST: "GetMap",
          transparent: true,
          layers: process.env.VUE_APP_CRANES,
          format: "image/png",
          version: "1.1.1",
          visible: true,
        }
      );
    },
    async swapImgsDynamic() {
      this.incrementRedrawInteger();

      if (process.env.VUE_APP_SECURITY_AREAS == "true") {
        this.map.removeLayer(this.layerSecurityAreasActualStatic);
        this.wmsvessellSecurityAreasStatic();
        this.map.addLayer(this.layerSecurityAreasActualStatic);
      }
      if (process.env.VUE_APP_GEOSERVER_CONTAINERS && process.env.VUE_APP_GEOSERVER_CONTAINERS !== "") {
        this.map.removeLayer(this.layerContainer);
        this.wmsContainers();
        this.map.addLayer(this.layerContainer);
      }      
      this.map.removeLayer(this.layerActualDynamic);
      this.wmsvessellPoligon();
      this.map.addLayer(this.layerActualDynamic);

      this.map.removeLayer(this.layerActualCraneDynamic);
      this.wmscranePoligon();
      this.map.addLayer(this.layerActualCraneDynamic);

      this.layerActualDynamic.bringToBack();
      this.layerSecurityAreasActualStatic.bringToBack();
      this.layerActualCraneDynamic.bringToFront();
    },
    removeCurrentStatic() {
      this.map.removeLayer(this.layerActualStatic);
      // this.map.removeLayer(this.layerActualNombreStatic);
      this.map.removeLayer(this.layerSecurityAreasActualStatic);

      //console.log("remover ambas capa");
      var swap = this.layerCargaStatic;
      // var swap2 = this.layerCargaNombreStatic;
      var swap3 = this.layerSecurityAreasCargaStatic;

      this.layerActualStatic = swap;
      // this.layerActualNombreStatic = swap2;
      this.layerSecurityAreasActualStatic = swap3;
      //console.log("cambio de capa actual");
    },
    removeCurrentDynamic() {
      this.map.removeLayer(this.layerActualDynamic);
      this.map.removeLayer(this.layerActualNombreDynamic);
      // this.map.removeLayer(this.layerSecurityAreasActualDynamic);

      //console.log("remover ambas capa");
      var swap = this.layerCargaDynamic;
      var swap2 = this.layerCargaNombreDynamic;
      // var swap3 = this.layerSecurityAreasCargaDynamic;
      this.layerActualDynamic = swap;
      this.layerActualNombreDynamic = swap2;
      // this.layerSecurityAreasActualDynamic = swap3;
      //console.log("cambio de capa actual");
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    getcoords(e) {
      if (this.show === true) {
        this.show = false;
      }
      if (this.showPU === true) {
        this.showPU = false;
      }
      if (this.showP === true) {
        this.showP = false;
      }
      var layerHere = this.map.hasLayer(this.group1);
      if (layerHere === true) {
        this.map.removeLayer(this.group1);
      }
      this.lat = e.latlng.lat;
      this.lon = e.latlng.lng;

      axios
        .post(
          process.env.VUE_APP_API.trim() + "/api/tramas/glovo",
          {
            latitude: this.lat,
            length: this.lon,
          },
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(authService.getUserLogged()).token,
            },
          }
        )
        .then(async (response) => {
          if (response.data.message !== "Message No Data ") {
            if (response.data.data.imo || response.data.data.mmsi) {
              if (response.data.data.name === null) {
                response.data.data.name = "Desconocido";
              }

              if (response.data.data.type === null) {
                response.data.data.type = "Desconocido";
              }

              if (response.data.data.eta === "") {
                response.data.data.eta = null;
              }
              if (response.data.data.etd === "") {
                response.data.data.etd = null;
              }

              if (response.data.data.imo) {
                if (response.data.data.imo.indexOf("MMSI") === 0) {
                  response.data.data.imo = null;
                }
              }

              if (response.data.data.gt === "0") {
                response.data.data.gt = null;
              }

              if (response.data.data.gt === "") {
                response.data.data.gt = null;
              }
              if (response.data.data.maximumdraught === "0") {
                response.data.data.maximumdraught = null;
              }

              //date transformation regex
              const regexPattern = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})Z$/;

              //if service is enabled
              let predicted_etd = null;
              let predicted_etb = null;
              let predicted_eta = null;
              let predicted_etc = null;

              if ((/true/).test(process.env.VUE_APP_PREDICTION)) {
                //calculate predictions
                try {
                  predicted_eta = await predictionServices.eta_prediction(response.data.data);
                  predicted_etb = await predictionServices.etb_prediction(response.data.data, predicted_eta.data["dynamic-eta"]);
                  predicted_etc = await predictionServices.etc_prediction(response.data.data, predicted_etb.data["dynamic-etb"]);
                  predicted_etd = await predictionServices.etd_prediction(response.data.data, predicted_etc.data["dynamic-etc"]);
                } catch (error) {
                  console.error(error);
                }
              }

              this.vessellPopup = {
                callsign: response.data.data.callsign,
                port: response.data.data.port,
                etd: predicted_etd != null ? predicted_etd.data["dynamic-etd"].replace(regexPattern, '$3/$2 $4:$5') : "-",
                eta: predicted_eta != null ? predicted_eta.data["dynamic-eta"].replace(regexPattern, '$3/$2 $4:$5') : "-",
                etb: predicted_etb != null ? predicted_etb.data["dynamic-etb"].replace(regexPattern, '$3/$2 $4:$5') : "-",
                etc: predicted_etc != null ? predicted_etc.data["dynamic-etc"].replace(regexPattern, '$3/$2 $4:$5') : "-",
                draft: response.data.data.maximumdraught,
                gt: response.data.data.gt,
                dwt: response.data.data.dwt,
                distanceport: response.data.data.distanceport,
                distancepobr: response.data.data.distancebr,
                hdg: response.data.data.hdg,
                imo: response.data.data.imo,
                ship: response.data.data.name,
                sog: response.data.data.sog,
                state: response.data.data.state,
                typeship: response.data.data.type,
                utc: response.data.data.utc,
                sleeve: response.data.data.beam,
                length: response.data.data.length,
                picture: response.data.data.photo,
                noray1: response.data.data.norayinicio,
                noray2: response.data.data.norayfinal,
                mmsi: response.data.data.mmsi,
                recive: response.data.data.time,
              };

              let centerX = full_div.offsetLeft + full_div.offsetWidth / 2;
              let centerY = full_div.offsetTop + full_div.offsetHeight / 2;

              if (response.data.data.state === "T") {
                this.showHide();
                let radius = 100;
                this.group1 = L.featureGroup();
                var circle = L.circle([this.lat, this.lon], radius).addTo(
                  this.group1
                );
                this.map.addLayer(this.group1);
              }
              if (response.data.data.state === "S") {
                this.showHideS();
              }
              if (response.data.data.state === "E") {
                this.showHideE();
              }
              if (response.data.data.state === "M") {
                this.showHideM();
              }
              if (
                response.data.data.state === "BERTH" ||
                response.data.data.state === "BERTH_1" ||
                response.data.data.state === "ANCHORED"
              ) {
                this.showHidePU();
                let radius = 100;
                this.group1 = L.featureGroup();
                var circle = L.circle([this.lat, this.lon], radius).addTo(
                  this.group1
                );
                this.map.addLayer(this.group1);
              }
              if (response.data.data.state === "PILOTONBOARD") {
                this.showHideP();
                let radius = 100;
                this.group1 = L.featureGroup();
                var circle = L.circle([this.lat, this.lon], radius).addTo(
                  this.group1
                );
                this.map.addLayer(this.group1);
              }
            } else if (response.data.data.entity_type == "OilSpill") {
              this.oilSpillPopup = {
                id: response.data.data.entity_id,
                name: response.data.data.name,
                date: response.data.data.date,
                description: response.data.data.description,
                sensor: response.data.data.sensor,
                picture: response.data.data.previewurl,
                state: response.data.data.state,
                shoreDistance: response.data.data.shoredistance,
                reportedBy: response.data.data.statereportedby,
                minVolume: response.data.data.minvolume,
                maxVolume: response.data.data.maxvolume,
                sheenArea: response.data.data.sheenarea,
                thickArea: response.data.data.thickarea,
                windSpeed: response.data.data.windspeed,
                windDirection: response.data.data.winddirection,
                entityType: response.data.data.entity_type,
              };

              this.showHideOilSpillPopUp();
            }
          }
          else {
            axios
            .get(process.env.VUE_APP_GEOSERVER_ZIDAY_POPUP + this.lon + "," + this.lat + "," + this.lon + "," + this.lat)
            .then(
              (response) => {
                if(response.data.features != null && response.data.features.length > 0) {

                  this.oilSpillPopup = {
                    id: response.data.features[0].properties.EventId,
                    name: response.data.features[0].properties.Type.toUpperCase(),
                    date: response.data.features[0].properties.Created,
                    deliveryDate: response.data.features[0].properties.DeliveryDatetime,
                    acquisitionDate: response.data.features[0].properties.AcquisitionDatetime,
                    description: response.data.features[0].properties.Description,
                    sensor: response.data.features[0].properties.Sensor,
                    picture: response.data.features[0].properties.PreviewUrl,
                    state: response.data.features[0].properties.State,
                    minVolume: response.data.features[0].properties.MinVolume,
                    maxVolume: response.data.features[0].properties.MaxVolume,
                    sheenArea: response.data.features[0].properties.SheenArea,
                    thickArea: response.data.features[0].properties.ThickArea,
                    trueColorArea: response.data.features[0].properties.TrueColorArea,
                    windSpeed: response.data.features[0].properties.WindSpeed,
                    windDirection: response.data.features[0].properties.WindDirection,
                    oilType: response.data.features[0].properties.OilType,
                    eventClass: response.data.features[0].properties.EventClass,
                  };

                  this.showHideOilSpillPopUp();
                }
              }
            )
          }
        })
        .catch((error) => {
          console.log(error);
          axios
          .get(process.env.VUE_APP_GEOSERVER_ZIDAY_POPUP + this.lon + "," + this.lat + "," + this.lon + "," + this.lat)
          .then(
            (response) => {
              if(response.data.features != null && response.data.features.length > 0) {

                this.oilSpillPopup = {
                  id: response.data.features[0].properties.EventId,
                  name: response.data.features[0].properties.Type.toUpperCase(),
                  date: response.data.features[0].properties.Created,
                  description: response.data.features[0].properties.Description,
                  sensor: response.data.features[0].properties.Sensor,
                  picture: response.data.features[0].properties.PreviewUrl,
                  state: response.data.features[0].properties.State,
                  minVolume: response.data.features[0].properties.MinVolume,
                  maxVolume: response.data.features[0].properties.MaxVolume,
                  sheenArea: response.data.features[0].properties.SheenArea,
                  thickArea: response.data.features[0].properties.ThickArea,
                  windSpeed: response.data.features[0].properties.WindSpeed,
                  windDirection: response.data.features[0].properties.WindDirection,
                };

                this.showHideOilSpillPopUp();
              }
            }
          )
        });

      this.styleObject = {
        left: 130 + "px",
        top: 130 + "px",
      };
    },
    showHide: function () {
      this.show = true;
    },
    showHideE: function () {
      this.showE = true;
    },
    showHideS: function () {
      this.showS = true;
    },
    showHideM: function () {
      this.showM = true;
    },
    showHideP: function () {
      this.showP = true;
    },
    showHidePU: function () {
      this.showPU = true;
    },
    showHideN: function () {
      this.showN = !this.showN;
    },
    showHideOilSpillPopUp: function () {
      this.showOilSpillPopUp = true;
    },
    incidencia(e) {
      this.lat = e.latlng.lat;
      this.lon = e.latlng.lng;
      this.dialog = true;
    },
    panHandler(direction) {
      //console.log(direction)  // May be left / right / top / bottom
    },
    rotateHandler(direction) {
      //console.log(direction)  // May be left / right / top / bottom
    },
    renderDynamic() {
      this.swapImgsDynamic();
    },
    closeDialog() {
      this.dialog = false;
    },
    closeDialogBocadillo() {
      this.show = false;
      this.showS = false;
      this.showE = false;
      this.showM = false;
      this.showPU = false;
      this.showP = false;
      this.showOilSpillPopUp = false;
      this.map.removeLayer(this.group1);
    },
    reportIncident(type) {
      this.indice = 0;
      let incident = {
        latitude: this.lat,
        longitude: this.lon,
        type: type,
        indice: this.indice,
        port: process.env.VUE_APP_PUERTO,
        message: null,
        source: null,
        data: null,
      };

      axios
        .post(process.env.VUE_APP_API.trim() + "/api/incidents", incident, {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(authService.getUserLogged()).token,
          },
        })
        .then((result) => {
          this.dialog = false;
          if (incident.type === "barco") {
            this.incidencias.push({
              id: result.data.data.id,
              position: [this.lat, this.lon],
              visible: true,
              content: "Barco fonde:" + this.lat + "," + this.lon,
              icon: this.iconBarco,
            });
          }
          if (incident.type === "buzo") {
            this.incidencias.push({
              id: result.data.data.id,
              position: [this.lat, this.lon],
              visible: true,
              content: "Buzos:" + this.lat + "," + this.lon,
              icon: this.iconBuzo,
            });
          }
          if (incident.type === "grua") {
            this.incidencias.push({
              id: result.data.data.id,
              position: [this.lat, this.lon],
              visible: true,
              content: "Grua:" + this.lat + "," + this.lon,
              icon: this.iconGrua,
            });
          }
          if (incident.type === "barrera") {
            this.incidencias.push({
              id: result.data.data.id,
              position: [this.lat, this.lon],
              visible: true,
              content: "Barrera:" + this.lat + "," + this.lon,
              icon: this.iconBarrera,
            });
          }
          localStorage.setItem(
            "incidencias_palmas",
            JSON.stringify(this.incidencias)
          );
          this.layers[4].visible = true;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    onMarkerClick(infoContent) {},
    deleteMarker(index, id) {
      this.incidencias.splice(index, 1);

      axios
        .delete(process.env.VUE_APP_API.trim() + "/api/incidents/" + id, {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(authService.getUserLogged()).token,
          },
        })
        .then((result) => {
          localStorage.setItem("incidencias_palmas", JSON.stringify(result));
          //this.layers[4].visible = true;
        })
        .catch(function (error) {
          // console.log(error);
        });
    },
    deleteOilSpillMarker(oilSpill) {
      // this.oilSpills = this.oilSpills.filter((oilSpill) => {
      //   return oilSpill.id !== oilSpill.id;
      // });
      // const currentEvent = this.currentEvents.find((event) => {
      //   return event.additionaldata.id === oilSpill.id;
      // });
      // this.currentEvents = this.currentEvents.filter((event) => {
      //   return event.additionaldata.id !== oilSpill.id;
      // });
      // currentEvent.timestamp = new Date().toISOString();
      // currentEvent.senttimestamp = new Date().toISOString();
      // axios
      //   .put(
      //     process.env.VUE_APP_API.trim() + "/api/events",
      //     { currentEvent },
      //     {
      //       headers: {
      //         Authorization:
      //           "Bearer " + JSON.parse(authService.getUserLogged()).token,
      //       },
      //     }
      //   )
      //   .then((result) => {
      //     this.loadOilSpillMarkers();
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //   });

      zidayService.acknowledgeOilSpill(oilSpill.adquisitionId, oilSpill.id).then(async (result) => {
        this.oilSpills = this.oilSpills.filter((oilSpill) => {
          return oilSpill.id !== oilSpill.id;
        });
        this.loadOilSpillMarkers(0);
        this.map.removeLayer(this.layerOilSpills);
        await new Promise((resolve) => setTimeout(resolve, 10000));
        this.wmsvessellOilSpills();
        this.map.addLayer(this.layerOilSpills);
      })
      .catch(function (error) {
        if (error.response.status == 401) {
          keycloakService.login();
          this.zidayService.acknowledgeOilSpill(oilSpill.adquisitionId, oilSpill.id).then(async (result) => {
            this.oilSpills = this.oilSpills.filter((oilSpill) => {
              return oilSpill.id !== oilSpill.id;
            });
            this.loadOilSpillMarkers(0);
            this.map.removeLayer(this.layerOilSpills);
            await new Promise((resolve) => setTimeout(resolve, 10000));
            this.wmsvessellOilSpills();
            this.map.addLayer(this.layerOilSpills);
          })
        }
      });
    },
    deleteWastewaterMarker(wastewater) {
      zidayService.acknowledgeWastewater(wastewater.adquisitionId, wastewater.id).then(async (result) => {
        this.wastewaters = this.wastewaters.filter((wastewater) => {
          return wastewater.id !== wastewater.id;
        });
        this.loadOilSpillMarkers(0);
        this.map.removeLayer(this.layerWastewaters);
        await new Promise((resolve) => setTimeout(resolve, 10000));
        this.wmsvessellWastewaters();
        this.map.addLayer(this.layerWastewaters);
      })
      .catch(function (error) {
        if (error.response.status == 401) {
          keycloakService.login();
          this.zidayService.acknowledgeWastewater(wastewater.adquisitionId, wastewater.id).then(async (result) => {
            this.wastewaters = this.wastewaters.filter((wastewater) => {
              return wastewater.id !== wastewater.id;
            });
            this.loadOilSpillMarkers(0);
            this.map.removeLayer(this.layerWastewaters);
            await new Promise((resolve) => setTimeout(resolve, 10000));
            this.wmsvessellWastewaters();
            this.map.addLayer(this.layerWastewaters);
          })
        }
      });
    },
    startInterval: function () {
      setInterval(() => {
        this.renderDynamic();
      }, process.env.VUE_APP_REFRESH_TIME_TABLE);
    },
    startMessage() {
      setTimeout(() => {
        this.Message();
      }, 3500);
    },
    async estadoInicial() {
      axios
        .get(
          process.env.VUE_APP_API_PORT_ESTATE.trim() +
            process.env.VUE_APP_PUERTO.trim() +
            "|" +
            process.env.VUE_APP_USER.trim() +
            "|" +
            process.env.VUE_APP_PASS.trim(),
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(authService.getUserLogged()).token,
            },
          }
        )
        .then((result) => {
          // console.log("logré estado portuario ");
          //console.log("estado portuario " + result);
          //console.log("Estado Portuario: " + process.env.VUE_APP_PUERTO.trim());
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
    batimetria() {
      if (this.varBatimetria === false) {
        this.layers[2].visible = true;
        this.layers[3].visible = true;
        this.varBatimetria = true;
      } else {
        this.layers[2].visible = false;
        this.layers[3].visible = false;
        this.varBatimetria = false;
      }
    },
    salidas() {
      // console.log("Configurar Amura");
    },
    muelles() {
      if (this.layers[8].visible === true) {
        this.layers[8].visible = false;
        this.layers[9].visible = true;
      } else {
        this.layers[8].visible = true;
        this.layers[9].visible = false;
      }
    },
    entradas() {
      // console.log("Configurar Amura");
    },
    accident() {
      // if (this.layers[12].visible === true) {
      //   this.layers[12].visible = false;
      // } else {
      //   this.layers[12].visible = true;
      // }
      this.incidencias.forEach((incident) => {
        if (incident.visible === true) {
          incident.visible = false;
          document.querySelectorAll(".accident-icon path").forEach((path) => {
            path.style.fill = "#343d66";
          });
        } else {
          incident.visible = true;
          document.querySelectorAll(".accident-icon path").forEach((path) => {
            path.style.fill = "#fba83e";
          });
        }
      });
    },
    POB() {
      if (this.layers[3].visible === true) {
        this.layers[3].visible = false;
      } else {
        this.layers[3].visible = true;
      }
    },
    move() {
      if (this.layers[10].visible === true) {
        this.layers[10].visible = false;
        this.layers[9].visible = true;
      } else {
        this.layers[10].visible = true;
        this.layers[9].visible = false;
      }
    },
    PopUpHttp(data) {
      if (this.show === true) {
        this.show = false;
      }
      if (this.showPU === true) {
        this.showPU = false;
      }
      if (this.showP === true) {
        this.showP = false;
      }
      var layerHere = this.map.hasLayer(this.group1);
      if (layerHere === true) {
        this.map.removeLayer(this.group1);
      }
      this.lat = parseFloat(data.latitude);
      this.lon = parseFloat(data.longuitude);
      if (data.name === null) {
        data.name = "Desconocido";
      }
      if (data.type === null) {
        data.type = "Desconocido";
      }
      if (data.subtype === null) {
        data.subtype = "Desconocido";
      }
      this.vessellPopup = {
        name: data.name,
        type: data.type,
        subtype: data.subtype,
        callsign: data.callsign,
        port: data.port,
        etd: data.etd,
        eta: data.eta,
        draft: data.maximumdraught,
        gt: data.gt,
        dwt: data.dwt,
        distanceport: data.distanceport,
        distancepobr: data.distancebr,
        hdg: data.hdg,
        imo: data.imo,
        ship: data.name,
        sog: data.sog,
        state: data.state,
        typeship: data.type,
        utc: data.utc,
        sleeve: data.beam,
        length: data.length,
        picture: data.photo,
        noray1: data.norayinicio,
        noray2: data.norayfinal,
        mmsi: data.mmsi,
        recive: data.time,
      };
      let centerX = full_div.offsetLeft + full_div.offsetWidth / 2;
      let centerY = full_div.offsetTop + full_div.offsetHeight / 2;

      if (data.state === "T") {
        this.showHide();
        let radius = 100;
        this.group1 = L.featureGroup();
        var circle = L.circle([this.lat, this.lon], radius).addTo(this.group1);
        this.map.addLayer(this.group1);
      }
      if (data.state === "S") {
        this.showHideS();
      }
      if (data.state === "E") {
        this.showHideE();
      }
      if (data.state === "M") {
        this.showHideM();
      }
      if (
        data.state === "BERTH" ||
        data.state === "BERTH_1" ||
        response.data.data.state === "ANCHORED"
      ) {
        this.showHidePU();
        let radius = 100;
        this.group1 = L.featureGroup();
        var circle = L.circle([this.lat, this.lon], radius).addTo(this.group1);
        this.map.addLayer(this.group1);
      }
      if (data.state === "PILOTONBOARD") {
        this.showHideP();
        let radius = 100;
        this.group1 = L.featureGroup();
        var circle = L.circle([this.lat, this.lon], radius).addTo(this.group1);
        this.map.addLayer(this.group1);
      }
      this.styleObject = {
        left: 130 + "px",
        top: 130 + "px",
      };
    },
    addIncident(incident) {
      let cords = incident.coord.split(",", 2);
      if (incident.type === "barco") {
        this.incidencias.push({
          position: [cords[0], cords[1]],
          visible: true,
          content: "Barco fonde:" + incident.coord,
          id: incident.id,
          icon: this.iconBarco,
        });
      }
      if (incident.type === "buzo") {
        this.incidencias.push({
          position: [cords[0], cords[1]],
          visible: true,
          content: "Buzos:" + incident.coord,
          id: incident.id,
          icon: this.iconBuzo,
        });
      }
      if (incident.type === "grua") {
        this.incidencias.push({
          position: [cords[0], cords[1]],
          visible: false,
          content: "Grua:" + incident.coord,
          id: incident.id,
          icon: this.iconGrua,
        });
      }
      if (incident.type === "barrera") {
        this.incidencias.push({
          position: [cords[0], cords[1]],
          visible: true,
          content: "Barrera:" + incident.coord,
          id: incident.id,
          icon: this.iconBarrera,
        });
      }
      if (incident.type === "spill") {
        this.incidencias.push({
          position: [cords[0], cords[1]],
          visible: true,
          content: "Oil spill:" + incident.coord,
          id: incident.id,
          icon: this.iconOilSpill,
        });
      }
    },
    addOilSpillMarker(oilSpill) {
      let cords = oilSpill.centroid.coordinates;
      this.oilSpills.push({
        position: [cords[1], cords[0]],
        visible: true,
        content: "Oil spill:" + oilSpill.centroid.coordinates,
        id: oilSpill.id,
        adquisitionId: oilSpill.adquisitionId,
        icon: this.iconOilSpill,
      });
    },
    addWastewaterMarker(wastewater) {
      let cords = wastewater.centroid.coordinates;
      this.wastewaters.push({
        position: [cords[1], cords[0]],
        visible: true,
        content: "Wastewater spill:" + wastewater.centroid.coordinates,
        id: wastewater.id,
        adquisitionId: wastewater.adquisitionId,
        icon: this.iconWastewater,
      });
    },
    removeIncident(incident) {
      this.incidencias = this.incidencias.filter((item) => {
        return item.id !== incident.id;
      });
    },
    async processEvent(event) {
      const receivedEvent = new amuraEvent(
        event.id,
        event.version,
        event.timestamp,
        event.reportedAt,
        event.reportedBy,
        event.eventType,
        event.event,
        event.additionalData,
        event.vendorExtension
      );
      if (receivedEvent.additionalData.type === "OilSpill") {
        if (this.oilSpills.some((oilSpill) => oilSpill.id === receivedEvent.additionalData.id)) 
        {
          this.oilSpills = this.oilSpills.filter((oilSpill) => {
            return oilSpill.id !== receivedEvent.additionalData.id;
          });
          this.currentEvents = this.currentEvents.filter((event) => {
            return event.additionalData.id !== receivedEvent.additionalData.id;
          });
          this.addOilSpillMarker(receivedEvent.additionalData);
          this.currentEvents.push(receivedEvent);
        } 
        else if (this.oilSpills.some((oilSpill) => oilSpill.id === receivedEvent.additionalData.id) &&
          receivedEvent.eventType === "OilSpillAcknowledge") 
        {
          this.oilSpills = this.oilSpills.filter((oilSpill) => {
            return oilSpill.id !== receivedEvent.additionalData.id;
          });
          this.currentEvents = this.currentEvents.filter((event) => {
            return event.additionalData.id !== receivedEvent.additionalData.id;
          });
          this.map.removeLayer(this.layerOilSpills);
          await new Promise((resolve) => setTimeout(resolve, 10000));
          this.wmsvessellOilSpills();
          this.map.addLayer(this.layerOilSpills);

        } 
        else if (receivedEvent.eventType === "OilSpillDetected") 
        {
          this.addOilSpillMarker(receivedEvent.additionalData);
          this.currentEvents.push(receivedEvent);
          this.map.removeLayer(this.layerOilSpills);
          await new Promise((resolve) => setTimeout(resolve, 10000));
          this.wmsvessellOilSpills();
          this.map.addLayer(this.layerOilSpills);
        }
      } 
      if (receivedEvent.additionalData.type === "Wastewater") {
        if (this.wastewaters.some((wastewater) => wastewater.id === receivedEvent.additionalData.id)) 
        {
          this.wastewaters = this.wastewaters.filter((wastewater) => {
            return wastewater.id !== receivedEvent.additionalData.id;
          });
          this.currentEvents = this.currentEvents.filter((event) => {
            return event.additionalData.id !== receivedEvent.additionalData.id;
          });
          this.addWastewaterMarker(receivedEvent.additionalData);
          this.currentEvents.push(receivedEvent);
        } 
        else if (this.wastewaters.some((wastewater) => wastewater.id === receivedEvent.additionalData.id) &&
          receivedEvent.eventType === "WastewaterAcknowledge") 
        {
          this.wastewaters = this.wastewaters.filter((wastewater) => {
            return wastewater.id !== receivedEvent.additionalData.id;
          });
          this.currentEvents = this.currentEvents.filter((event) => {
            return event.additionalData.id !== receivedEvent.additionalData.id;
          });
          this.map.removeLayer(this.layerWastewaters);
          await new Promise((resolve) => setTimeout(resolve, 10000));
          this.wmsvessellWastewaters();
          this.map.addLayer(this.layerWastewaters);

        } 
        else if (receivedEvent.eventType === "WastewaterDetected") 
        {
          this.addWastewaterMarker(receivedEvent.additionalData);
          this.currentEvents.push(receivedEvent);
          this.map.removeLayer(this.layerWastewaters);
          await new Promise((resolve) => setTimeout(resolve, 10000));
          this.wmsvessellWastewaters();
          this.map.addLayer(this.layerWastewaters);
        }
      } 
      else {
        console.error(
          "the event type " + receivedEvent.eventType + " is not supported"
        );
      }
    },
    Message() {
      Pusher.logToConsole = true;
      var pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
        cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
      });
      const evento = process.env.VUE_APP_PUERTO;
      var channel = pusher.subscribe("my-channel");
      channel.bind(evento, (data) => {
        if (data.imo != null) {
          this.PopUpHttp(data);
        } else if (data.delete_at != null) {
          this.removeIncident(data);
        } else if (data.type != null) {
          this.addIncident(data);
        }
      });
      channel.bind("AE", (data) => {
        this.processEvent(data);
      });
    },
  },
};
</script>
<style scoped>

.iconPopClose {
  background-color: black;
  border-radius: 50px;
}
h2 {
  line-height: 1 !important;
  font-size: 12pt !important;
}
.arrow_box {
  position: relative;
  background: green;
  border: 1 px solid green;
}

.arrow_boxS {
  position: relative;
  background: #abb7b7;
  border: 1 px solid #abb7b7;
}

.arrow_boxE {
  position: relative;
  background: #b877db;
  border: 1 px solid #b877db;
}

.arrow_boxM {
  position: relative;
  background: #f5fa5e;
  border: 1 px solid #f5fa5e;
}

.arrow_boxP {
  position: relative;
  background: #f5fa5e;
  border: 1 px solid #f5fa5e;
}

.bocadillo {
  /* min-width: 15vw;
    min-height: 6vh;*/
  background-color: green;
  position: absolute;
  top: var(--pointsX);
  left: var(--pointsY);
  z-index: 12;
  border-radius: 10px;
  text-align: inherit;
  padding: 2px;
  color: white;
  font-size: 16px;
  width: 217px;
}

.bocadilloS {
  /* min-width: 15vw;
    min-height: 6vh;*/
  background-color: #abb7b7;
  position: absolute;
  top: var(--pointsX);
  left: var(--pointsY);
  z-index: 12;
  border-radius: 10px;
  text-align: inherit;
  padding: 2px;
  color: white;
  font-size: 16px;
}

.bocadilloE {
  /* min-width: 15vw;
    min-height: 6vh;*/
  background-color: #293a5e;
  position: absolute;
  top: var(--pointsX);
  left: var(--pointsY);
  z-index: 12;
  border-radius: 10px;
  text-align: inherit;
  padding: 2px;
  color: white;
  font-size: 16px;
}

.bocadilloM {
  /* min-width: 15vw;
    min-height: 6vh;*/
  background-color: #abed00;
  position: absolute;
  top: var(--pointsX);
  left: var(--pointsY);
  z-index: 12;
  border-radius: 10px;
  text-align: inherit;
  padding: 2px;
  color: white;
  font-size: 16px;
}
.bocadilloP {
  /* min-width: 15vw;
    min-height: 6vh;*/
  background-color: #f5fa5e;
  position: absolute;
  top: var(--pointsX);
  left: var(--pointsY);
  z-index: 12;
  border-radius: 10px;
  text-align: inherit;
  padding: 2px;
  color: white;
  font-size: 16px;
  width: 217px;
}

.bocadilloPU {
  /* min-width: 15vw;
    min-height: 6vh;*/
  background-color: #ff4b66;
  position: absolute;
  top: var(--pointsX);
  left: var(--pointsY);
  z-index: 12;
  border-radius: 10px;
  text-align: inherit;
  padding: 2px;
  color: white;
  font-size: 16px;
  width: 217px;
}

.bocadilloOilSpill {
  /* min-width: 15vw;
    min-height: 6vh;*/
  background-color: #ff4b66;
  position: absolute;
  top: var(--pointsX);
  left: var(--pointsY);
  z-index: 12;
  border-radius: 10px;
  text-align: inherit;
  padding: 2px;
  color: white;
  font-size: 16px;
  width: 277px;
}

.TituloShip {
  color: white;
  margin-left: 10px;
  line-height: 0.1 !important;
  padding-top: 8px;
  font-size: 9pt;
}

.TituloShipP {
  color: black;
  margin-left: 10px;
  line-height: 0.1 !important;
  padding-top: 8px;
  font-size: 9pt;
}

.bocadillo .textoBocadillo,
.bocadilloS .textoBocadillo,
.bocadilloE .textoBocadillo,
.bocadilloM .textoBocadillo,
.bocadilloP .textoBocadillo,
.bocadilloPU .textoBocadillo,
.bocadilloOilSpill .textoBocadillo {
  background-color: #f0fed3;
  color: black;
  line-height: 0.5;
  /*margin-top: 7px;*/
  font-size: 11pt;
  padding: 11px;
  padding-bottom: 1px;
  border-radius: 10px;
  padding-left: 20px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.textoBocadillo .description {
  line-height: 1.5rem;
}

.dialogBox {
  width: 100%;
  height: 219px !important;
}
.botonClose {
  width: "100%";
  text-align: right;
}

.logoAmura {
  max-width: 150px;
}

.botonClosePopup {
  margin-top: -44px;
  margin-left: 176px;
  clear: both;
  position: absolute;
}

.bocadilloOilSpill .botonClosePopup {
  margin-top: -38px;
  margin-left: 238px;
  clear: both;
  position: absolute;
}

.txtCard {
  width: "100%";
  text-align: center !important;
}
.leaflet-popup-tip,
.leaflet-popup-content-wrapper {
  background: white !important;
  color: black !important;
}
.v-card {
  width: 100%;
  height: 200px;
}

.theme--light.v-btn:not(.v-btn--icon):not(.v-btn--flat) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
button {
  width: 20%;
  height: 54px;
}
.pin {
  width: 75%;
}

.popupFlexCont {
  width: 400px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
}

.popupFlexFoto {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
}

.popupFooter {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
}

.itemBandera {
  width: 13% !important;
  border: 1px solid aqua;
  padding: 3px;
  background-color: aqua;
  border-radius: 9px;
}
.fotoBandera {
  width: 100%;
}

.itemNombreBarco {
  color: blue;
  text-transform: uppercase;
  font-weight: bolder;
}

.typeship {
  color: black;
  font-weight: 100;
  text-transform: none;
}

.iconoRotacion {
  transform: rotateY(60deg);
}

.tile,
tile:hover {
  color: "primary";
}
.tile:active {
  color: "white";
}

.theme--light.v-btn:not(.v-btn--icon):not(.v-btn--flat) {
  background-color: #171c31 !important;
  box-shadow: none !important;
}

.theme--light.v-btn {
  color: #171c31 !important;
}

.v-toolbar__content {
  padding: 0px !important;
}

.borde {
  border: 5px white solid;
  border-radius: 25px;
}

.fondosal {
  border-color: #abb7b7;
  background-color: #abb7b7;
}

.fondoent {
  border-color: #b877db;
  background-color: #b877db;
}

.fondobc {
  border-color: #abed00;
  background-color: #abed00;
}
.fondoPOB {
  border-color: #f5fa5e;
  background-color: #f5fa5e;
}

.fondobar {
  border-color: #ff4b66;
  background-color: #ff4b66;
}

.fondobat {
  border-color: #84cbff;
  background-color: #84cbff;
}

.fondoet {
  border-color: #76c888;
  background-color: #76c888;
}

.textcolor {
  color: white;
}

.v-btn {
  /* -webkit-box-align: center; */
  -ms-flex-align: center;
  /* align-items: center; */
  /* border-radius: 2px; */
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 36px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 14px;
  font-weight: 500;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 6px -5px;
  min-width: 88px;
  outline: 0;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
  position: relative;
  /* vertical-align: middle; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.v-sheet {
  border-radius: 0px !important;
}
.fondoBarra {
  background-color: #2935a3;
}

.theme--light.v-btn:not(.v-btn--icon):not(.v-btn--flat) {
  background-color: #171c31 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.detaBarra {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  height: auto;
}

.entradasBtm {
  width: 2vw;
  height: 2vw;
}

.incidentBtmBtm {
  width: 2vw;
  height: 2vw;
}

.buquesBtm {
  width: 2vw;
  height: 2vw;
}

.leaflet-layer {
  z-index: 1 !important;
}

/*//////////ESTILOS PORTRAIT ////////////////////////////////*/
@media only screen and (orientation: portrait) {
  .logoAmura {
    max-width: 150px;
    margin-left: 40px;
  }

  .entradasBtm {
    width: 8vw;
    height: 5vw;
  }

  .incidentBtmBtm {
    width: 4vw;
    height: 4vw;
  }

  .buquesBtm {
    width: 4vw;
    height: 4vw;
  }

  .botonClosePopup {
    margin-top: -4vh;
    margin-left: 24.5vw;
    clear: both;
    position: absolute;
  }

  .bocadilloPU {
    /* min-width: 15vw;
    min-height: 6vh;*/
    background-color: #ff4b66;
    position: absolute;
    top: calc(500 - var(--pointsX));
    left: var(--pointsY);
    z-index: 12;
    border-radius: 10px;
    text-align: inherit;
    padding: 2px;
    color: white;
    font-size: 16px;
  }
}

.divider {
  border-color: #697ea8;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0 !important;
}

.logout {
  margin: auto;
  cursor: pointer;
}
</style>
<style lang="scss" scoped>
.v-card {
  background-color: #171c31 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>
