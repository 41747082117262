import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_PREDICTION_API;

export default {

  async etd_prediction(vesselData: any, datetime: string) {
    let etd = null;
    if (vesselData != null) {
      await axios.post(
        ENDPOINT_PATH + "/etd",
        {
          "ship_type": 70,
          "to_bow": Number(vesselData.tobow),
          "to_stern": Number(vesselData.tostearn),
          "to_starboard": Number(vesselData.tostartboard),
          "to_port": Number(vesselData.toport),
          "datetime": datetime ?? new Date().toISOString()
        }
      ).
      then((response) => {
        etd = response;
      }).
      catch((error) => {
        console.log("error", error);
        return etd;
      });
    }
    return etd;
  },
  async etb_prediction(vesselData: any, datetime: string) {
    let etb = null;
    if (vesselData != null) {
      await axios.post(
        ENDPOINT_PATH + "/etb",
        {
          "ship_type": 70,
          "to_bow": Number(vesselData.tobow),
          "to_stern": Number(vesselData.tostearn),
          "to_starboard": Number(vesselData.tostartboard),
          "to_port": Number(vesselData.toport),
          "datetime": datetime ?? new Date().toISOString()
        }
      ).
      then((response) => {
        etb = response;
      }).
      catch((error) => {
        console.log("error", error);
        return etb;
      });
    }
    return etb;
  },
  async eta_prediction(vesselData: any) {
    let eta = null;
    if (vesselData != null) {
      await axios.post(
        ENDPOINT_PATH + "/eta",
        {
          "ship_type": 70,
          "lat": Number(vesselData.latitude),
          "lon": Number(vesselData.longuitude),
          "speed": Number(vesselData.sog),
            }
      ).
      then((response) => {
        eta = response;
      }).
      catch((error) => {
        console.log("error", error);
        return eta;
      });
    }
    return eta;
  },
  async etc_prediction(vesselData: any, datetime: string) {
    let etc = null;
    if (vesselData != null) {
      await axios.post(
        ENDPOINT_PATH + "/etc",
        {
          "to_bow": Number(vesselData.tobow),
          "to_stern": Number(vesselData.tostearn),
          "to_starboard": Number(vesselData.tostartboard),
          "to_port": Number(vesselData.toport),
          "datetime": datetime ?? new Date().toISOString()
        }
      ).
      then((response) => {
        etc = response;
      }).
      catch((error) => {
        console.log("error", error);
        return etc;
      });
    }
    return etc;
  }
};

